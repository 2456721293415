body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-form-item {
    margin-bottom: 8px !important;
}

.ant-form-item-row {
    display: block !important;
}

.ant-menu-item-group-title {
    color: #bfbfbf !important;
}

.ant-form-item-label {
    font-weight: 500 !important;
    padding-bottom: 0 !important;
}

/* width */
::-webkit-scrollbar {
    width: 1px;
    height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}